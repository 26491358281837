<template>
  <div>
    <v-app>
      <div class="row dashboard_envios">
        <div class="col">
          <v-card>
            <v-card-title>
              Envíos en el sistema
              <v-spacer></v-spacer>
              <v-btn @click="clearSelected" v-show="selected.length" color="error" class="float-center mr-3" >
                <v-icon small center dark>mdi-delete</v-icon>Limpiar selección
              </v-btn>
              <v-btn
                @click="generateMassiveTags"
                small
                text
                color="success"
                class="float-left"
                :disabled="!selected.length"
              >
                <v-icon left dark>mdi-format-list-checks</v-icon>Imprimir Seleccionadas
              </v-btn>
              <div class="mx-0">
                <v-btn color="primary" icon x-small dark v-show="selected.length == 1">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </div>
              <v-btn class @click="fetchDataPaginated" text icon color="blue">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-card outlined tile elevation="1">
                <v-card-text style="padding-bottom: 0px;">
                  <div class="d-flex justify-space-between mb-4">
                    <v-text-field
                      v-model="search"
                      clearable
                      label="Buscar"
                      hide-details
                      dense
                      solo
                      @change="detectFilterChange"
                      class="mr-2"
                    ></v-text-field>
                    <v-checkbox label="Búsqueda exacta" v-model="busquedaExacta">
                      Búsqueda exacta
                    </v-checkbox>
                    <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;" @click="detectFilterChange" >Buscar</v-btn>
                  </div>
                  <div class="d-flex justify-space-between">
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_calendar"></i> Fecha</span>
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        style="border-bottom: 1px solid #000;"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click:clear="limpiarRango"
                            v-model="dateRangeText"
                            placeholder="Fecha"
                            solo
                            readonly
                          ></v-text-field>
                        </template>
                        <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates"></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><i class="icon-shipments"></i> Courier</span>
                      <v-select
                        @change="verRefSelect"
                        class="ml-2 mr-2"
                        label="Filtro por courier"
                        ref="select_carrier"
                        :items="carrierList"
                        v-model="selectCarrier"
                        item-text="text"
                        @input="detectFilterChange"
                        solo
                      ></v-select>
                    </div>
                    <!--
                      <div class="d-flex flex-column">
                        <span class="text-h6 font-weight-regular black--text"><i class="icon-warehouses"></i> Bodega</span>
                        <v-select
                          class="ml-2 mr-2" label="Filtro por bodega" :items="warehousesList" v-model="selectWarehouses"
                          item-text="name" @input="fetchDataPaginated" solo
                        ></v-select>
                      </div>
                    -->
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><i class="icon-warehouses"></i> Store</span>
                      <v-select
                        class="ml-2 mr-2"
                        label="Filtro por Store"
                        :items="storesList"
                        v-model="selectStores"
                        item-text="name"
                        @input="detectFilterChange"
                        solo
                      ></v-select>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><v-icon>mdi-file-document-edit-outline</v-icon> Estado manifiesto</span>
                      <v-select
                        class="ml-2 mr-2"
                        label="Filtro por manifiesto"
                        :items="manifestConditionList"
                        v-model="selectManifestCondition"
                        item-text="name"
                        @input="detectFilterChange"
                        solo
                      ></v-select>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="text-h6 font-weight-regular black--text"><v-icon>mdi-file-document-edit-outline</v-icon> Estado impresión</span>
                      <v-select
                        class="ml-2 mr-2"
                        label="Filtro por impresión"
                        :items="printedConditionList"
                        v-model="selectPrintedCondition"
                        item-text="name"
                        @input="detectFilterChange"
                        solo
                      ></v-select>
                    </div>
                    <div class="d-flex flex-column source_filter">
                      <span class="text-h6 font-weight-regular black--text "><v-icon>mdi-store</v-icon> Filtro por canal</span>
                      <v-autocomplete
                        v-model="selectSources" :items="sourcesList" chips clearable deletable-chips
                        label="" multiple item-text="name" item-value="id" v-on:change="checkSelection"
                      >
                        <template v-slot:item="data">
                          <template>
                            <v-list-item-content>
                              <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>

                    </div>
                    <div class="d-flex">
                      <v-btn
                        v-show="!!search || !!dateRangeText || selectCarrier != 'Todos' || selectWarehouses != 'Todos' || selectManifestCondition != 'Todos' || selectPrintedCondition != 'Todos' || selectProduct || selectSources.length != 0 "
                        @click="limpiarFiltros"
                        class="align-self-center" dark color="grey"
                      >Limpiar Filtros</v-btn>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header> Filtros Avanzados </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <div class="d-flex justify-space-between">
                            <div class="d-flex flex-column col-sm-6">
                              <span class="text-h6 font-weight-regular black--text"><v-icon>mdi-file-document-edit-outline</v-icon> Filtro por Producto</span>
                              <v-combobox
                                label="Filtro por productos" solo :items="listProducts" 
                                v-model="selectProduct" @input="fetchDataPaginated" clearable
                            ></v-combobox>
                          </div>
                        </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="tableData"
                show-select
                v-model="selected"
                no-results-text="No hay resultados disponibles"
                no-data-text="No hay datos disponibles"
                loading-text="Cargando datos..."
                :sort-by="'id'"
                :sort-desc="true"
                class="elevation-1 datatable"

                :loading="dataTableLoading"
                :disable-pagination="true"
                :hide-default-footer="true"
                :items-per-page="selectItemsPerPage"

                @input="shipmentSelected"
              >
              <template v-slot:item.order_id="{ item }">
                  <td v-if="item.order_id" class="text-center"><a :href="'/#/ordenes/'+item.order_id" >{{item.order_id}}</a></td>
                  <td v-if="!item.order_id" class="text-center">{{item.order_id}}</td>
                  
                </template>
                <template v-slot:item.shipping_number="{ item }">
                  <td v-if="item.tracking_carrier" class="text-center"><a :href="item.tracking_carrier" target="_blank" >{{item.shipping_number}}</a></td>
                  <td v-if="!item.tracking_carrier" class="text-center">{{item.shipping_number}}</td>
                </template>
                <template v-slot:item.updated_at="{ item }">
                  <td class="text-center">{{$moment(item.updated_at).format('LLLL')}}</td>
                </template>
                <template v-slot:item.estado="{ item }">
                  <td class="text-center">
                  </td>
                </template>
                <template v-slot:item.manifest="{ item }">
                  <td class="text-center">
                      <div v-if="item.manifest != null ">
                          <v-btn text icon color="green">
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                      </div>
                      <div v-else>
                        <v-btn text icon color="pink">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                      </div>
                  </td>
                </template>
                <template v-slot:item.acciones="{ item }">
                  <td class="d-flex">
                    <!-- <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          default
                          color="#8C8C8C"
                          class="mr-2"
                          v-on="on"
                          @click="$router.push({name: 'envio', params: {envio: item.id}})"
                        >mdi-arrow-right-box</v-icon>
                      </template>
                      <span>Ver detalles</span>
                    </v-tooltip> -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a :href="'/#/ordenes/'+item.id" >
                          <v-icon default color="#8C8C8C" class="mr-2" v-on="on" >mdi-cart-arrow-right</v-icon>
                        </a>                        
                      </template>
                      <span>Ver orden</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          default
                          color="#8C8C8C"
                          class="mr-2"
                          v-on="on"
                          @click="openDialogModificarNroSeguimiento(item)"
                        >mdi-truck-delivery</v-icon>
                      </template>
                      <span>Nro. Seguimiento</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }" v-if="((item.carrier.api_data != null && item.carrier_api_data_obj && item.carrier_api_data_obj.multi_package != 0)) && (item.shipment_state.type == 'DEFAULT_IN_SHIPPING' || item.shipment_state.type == 'DEFAULT_LABEL_OUT' || item.shipment_state.type == 'DEFAULT_OUT_SHIPMENT' )">
                        <v-icon
                          default
                          color="#8C8C8C"
                          class="mr-2"
                          v-on="on"
                          @click="openDialogModificarNroBultos(item)"
                        >mdi-counter</v-icon>
                      </template>
                      <span>Nro. Bultos</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }" v-if="(item.shipment_state.type == 'DEFAULT_IN_SHIPPING' || item.shipment_state.type == 'DEFAULT_LABEL_OUT' || item.shipment_state.type == 'DEFAULT_OUT_SHIPMENT' )">
                        <v-icon default color="#8C8C8C" class="mr-2" v-on="on" @click="openDialogRegenerarEtiqueta(item)" >mdi-barcode</v-icon>
                      </template>
                      <span>Regenerar Etiqueta</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="item.tracking_url">
                      <template v-slot:activator="{ on }">
                        <v-icon                          
                          style="font-size: 1.5rem !important;"
                          small
                          color="#8c8c8c"
                          class="mr-2"
                          v-on="on"
                          @click="openShippingLink(item.tracking_url)"
                        >mdi-truck-delivery</v-icon>
                      </template>
                      <span>Link Seguimiento</span>
                    </v-tooltip>
                    
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon                          
                          style="font-size: 1.5rem !important;"
                          small
                          color="#8c8c8c"
                          class="mr-2"
                          v-on="on"
                           @click="openDialogPreviewLabel(item)"
                        >mdi-note-search-outline</v-icon>
                      </template>
                      <span>Previsualización de Etiqueta</span>
                    </v-tooltip>

                  </td>
                </template>
                <template v-slot:item.label="{ item }">
                 <td v-if="item.printed != null">
                    <v-btn large color="warning" dark v-if="item.pdf !== ''" @click="openPdfLink(item)" >
                    <v-icon left dark>mdi-file</v-icon>Reimprimir</v-btn>
                  </td>
                  <td v-if="item.printed == null">
                    <v-btn large color="success" dark v-if="item.pdf !== ''" @click="openPdfLink(item)" >
                    <v-icon left dark>mdi-file</v-icon>Imprimir</v-btn>
                  </td>
                </template>
              
              </v-data-table>
              <div class="d-flex justify-space-between mt-4">
                  <v-select style="max-width: 120px" @input="fetchDataPaginated" v-model="selectItemsPerPage" label="Registros por página" :items="[5,10,20,50,100]"></v-select>
                  <v-pagination @input="fetchDataPaginated" v-model="page" :length="totalPages"></v-pagination>
              </div>

              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column col-12">
                  <v-alert prominent type="error" v-if="manifestErrorSelected" >
                    <v-row align="center"> <v-col class="grow"> Se deben seleccionar envíos de un mismo courier y tienda para generar el manifiesto. </v-col> </v-row>
                  </v-alert>
                </div>
              </div>

              <div class="d-flex justify-space-between">
                <div class="d-flex flex-column col-5">
                  <span class="text-h6 font-weight-regular black--text"><i class="icon-shipments"></i> Selección de Courier para generar Manifiesto</span>
                  <v-select v-model="selectCourier" :items="couriersList" :rules="[v => !!v || 'Debes elegir un courier']" label="Seleccione un courier" required ></v-select>
                </div>
                <div class="d-flex flex-column col-4">
                  <v-btn @click="finish" :disabled="!selected.length || !selectCourier || manifestErrorSelected" color="success" class="float-left mt-10" >
                    <v-icon left dark>mdi-format-list-checks</v-icon>Generar manifiesto de envios ({{selected.length}})
                  </v-btn>
                </div>
                <div class="d-flex flex-column col-3">
                  <v-btn @click="clearSelected" :disabled="!selected.length" color="error" class="float-left mt-10" >
                    <v-icon left dark>mdi-delete</v-icon>Limpiar selección
                  </v-btn>
                </div>
              </div>
              <!--
                <br /> <br />
                <v-select v-model="selectCourier" :items="couriersList" :rules="[v => !!v || 'Debes elegir un courier']" label="Seleccione un courier" required ></v-select>
                <br />
                <v-btn @click="finish" v-show="selected.length && selectCourier" small color="success" dark class="float-left" >
                  <v-icon left dark>mdi-format-list-checks</v-icon>Generar Manifiesto
                </v-btn>
              -->
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-app>
    <dialog-modificar-nro-seguimiento @success="fetchDataPaginated"></dialog-modificar-nro-seguimiento>
    <dialog-modificar-nro-bultos @success="fetchDataPaginated"></dialog-modificar-nro-bultos>
    <dialog-regenerar-etiqueta @success="fetchDataPaginated"></dialog-regenerar-etiqueta>
    <dialog-preview-label @success="fetchDataPaginated"></dialog-preview-label>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from "date-fns/format";
import DialogModificarNroSeguimiento from "@/views/oms/Envios/DialogModificarNroSeguimiento.vue";
import DialogModificarNroBultos from "@/views/oms/Envios/DialogModificarNroBultos.vue";
import DialogRegenerarEtiqueta from "@/views/oms/Envios/DialogRegenerarEtiqueta.vue";
import DialogPreviewLabel from "@/views/oms/Envios/DialogPreviewLabel.vue";
import EventBus from "@/event-bus";
export default {
  components: {
    DialogModificarNroSeguimiento,
    DialogModificarNroBultos,
    DialogRegenerarEtiqueta,
    DialogPreviewLabel,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dateFormat: "D MMM",
      dateOne: "",
      dateTwo: "",
      carrierList: [],
      warehousesList: [],
      storesList: [],
      manifestConditionList: [],
      printedConditionList: [],
      selectCarrier: 0,
      search: "",
      busquedaExacta: true,
      selected: [],

      tableData: [],
      dates: [],
      menu1: false,
      dateRangeText: "",
      listProducts: [],
      selectProduct: undefined,

      selected: [],
      couriersList: [],
      selectCourier: undefined,
      selectWarehouses: "Todos",
      selectManifestCondition: "Todos",
      selectPrintedCondition: "Todos",
      selectStores: "Todos",
      totalPages: 1,
      dataTableLoading: false,
      selectItemsPerPage: 10,
      page: 1,
      api_url: process.env.VUE_APP_API_URL,

      manifestErrorSelected: false,
      selectSources: [],
      sourcesList: [],
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard Envíos (Store Group)", route: "envios-storegroup", hide_store_logo: true },
    ]);
    //this.fetchDataPaginated();
    await this.fetchCouriers();
    await this.fetchSources();
    await this.fetchStores();
    this.fetchManifestConditionList();
    this.fetchPrintedConditionList();
    this.fetchProducts();
    this.getParamsFromUrl();
  },
  methods: {
    getParamsFromUrl(){
      var vm = this;
      console.log("this.$route.query", this.$route.query);

      if(this.$route.query.busqueda_exacta){
        vm.busquedaExacta = this.$route.query.busqueda_exacta;
      }
      if(this.$route.query.search){
        vm.search = this.$route.query.search;
      }
      if(this.$route.query.date){
        vm.dateRangeText = this.$route.query.date;
      }
      if(this.$route.query.courier){
        vm.selectCarrier = this.$route.query.courier;
      }
      if(this.$route.query.manifest){
        vm.selectManifestCondition = this.$route.query.manifest;
      }
      if(this.$route.query.printed){
        vm.selectPrintedCondition = this.$route.query.printed;
      }
      if(this.$route.query.sources){
        vm.selectSources = JSON.parse(this.$route.query.sources);
      }
      if(this.$route.query.store){
        vm.selectStores = this.$route.query.store;
      }

      this.fetchDataPaginated();
    },
    detectFilterChange(){
      var vm = this;
      let filters = {};

      if (vm.busquedaExacta) {
        filters['busqueda_exacta'] = vm.busquedaExacta;
      }
      if (vm.search && vm.search != "") {
        filters['search'] = vm.search;
      }
      if (vm.dateRangeText && vm.dateRangeText != "") {
        filters['date'] = vm.dateRangeText;
      }
      if (vm.selectCarrier != 0) {
        filters['courier'] = vm.selectCarrier;
      }
      if (vm.selectManifestCondition != "Todos") {
        filters['manifest'] = vm.selectManifestCondition;
      }
      if (vm.selectPrintedCondition != "Todos") {
        filters['printed'] = vm.selectPrintedCondition;
      }
      if (vm.selectSources.length > 0) {
        filters['sources'] = JSON.stringify(vm.selectSources);
      }
      if (vm.selectStores != "Todos") {
        filters['store'] = vm.selectStores;
      }
      
      this.$router.push({path: this.$route.path, query: filters });
      this.fetchDataPaginated();
    },
    fetchCarrierList() {
      var vm = this;
      this.axios({
        url: "shippings/carriers/get_data_list",
        method: "GET",
      })
        .then((response) => {
          vm.carrierList = response.data;
          vm.carrierList.push({ text: "Todos", value: 0 });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    limpiarFiltros() {
      this.search = "";
      this.busquedaExacta = true;
      this.dateRangeText = "";
      //this.selectCourier = "Todos";
      this.selectProduct = undefined;
      this.selectWarehouses = "Todos";
      this.selectManifestCondition = "Todos";
      this.selectPrintedCondition = "Todos";
      this.selectSources = [];
      this.selectStores = "Todos";
      this.selectCarrier = 0;
      this.detectFilterChange();
    },
    fetchDataPaginated(){
      this.dataTableLoading = true;
      var vm = this;
      const sortBy = 'id';
      const sortDesc = true;
      const page = this.page;
      const itemsPerPage = this.selectItemsPerPage;
      const search = this.search;
      const busquedaExacta = this.busquedaExacta;
      const filterManifestCondition = this.manifestConditionList.find(e => e.name == this.selectManifestCondition) ? this.manifestConditionList.find(e => e.name == this.selectManifestCondition).id : null;
      const filterPrintedCondition = this.printedConditionList.find(e => e.name == this.selectPrintedCondition) ? this.printedConditionList.find(e => e.name == this.selectPrintedCondition).id : null;
      const filterWarehouse = this.warehousesList.find(e => e.name == this.selectWarehouses) ? this.warehousesList.find(e => e.name == this.selectWarehouses).id : null;
      const filterStore = this.storesList.find(e => e.name == this.selectStores) ? this.storesList.find(e => e.name == this.selectStores).id : null;
      const startDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[0];
      const endDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[1];
      const filterCarrier = this.selectCarrier ? this.selectCarrier : null;
      const filterProduct = this.selectProduct ? this.selectProduct.value : null;
      const filterSources = this.selectSources;
      this.axios({
        url: "shippings/paginated/json/storegroup",
        method: "GET",
        params: {
          page,
          perPage: itemsPerPage,
          sortBy: sortBy,
          sortDesc: sortDesc ? 1 : 0,
          search,
          busquedaExacta,
          startDate,
          endDate,
          filterWarehouse,
          filterCarrier,
          filterStore,
          filterProduct,
          filterManifestCondition,
          filterPrintedCondition,
          filterSources,
        },
      })
      .then((response) => {
        //console.log(response);
        let arreglo = [];
        let arrStores = [];
        if(response.data.data instanceof Object){
            Object.keys(response.data.data).forEach(key => {
                arreglo.push(response.data.data[key]);
                /*
                if (!arrStores.find(e => e.id == response.data.data[key].store_id)) {
                  console.log(response.data.data[key].store_id);
                  arrStores.push({
                    id: response.data.data[key].store_id,
                    name: response.data.data[key].store,
                  });
                }
                */
            });
            
            vm.tableData = arreglo;
            /*if (vm.storesList.length === 0){
              vm.storesList = arrStores;
              vm.storesList.unshift({ id: null, name: "Todos"});
            }*/
        }else{
            vm.tableData = response.data.data.map(e => e);
        }
        vm.totalPages = response.data.last_page;
        vm.dataTableLoading = false;
      })
      .catch((error) => {
        console.log(error);
        vm.dataTableLoading = false;
      });
    },
    filtroRangoFechas(value) {
      if (!this.dateRangeText || !this.dates.length) {
        return true;
      } else {
        if (
          this.$moment(value).isSameOrAfter(this.$moment(this.dates[0])) &&
          this.$moment(value).isSameOrBefore(this.$moment(this.dates[1]))
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    verRefSelect() {
      console.log("select change", this.$refs.select_carrier.selectedItems);
    },
    filtroCarrier(v) {
      if (this.$refs.select_carrier.selectedItems[0]) {
        if (this.$refs.select_carrier.selectedItems[0].text == "Todos")
          return true;
        return this.$refs.select_carrier.selectedItems[0].text == v;
      } else {
        return true;
      }
    },
    formatearRango() {
      if (!this.dates.length) {
        this.dateRangeText = "";
      } else {
        this.dateRangeText = this.dates.join(" ~ ");
      }
      this.detectFilterChange();
    },
    limpiarRango() {
      this.dates = [];
    },
    async fetchCouriers() {
      var vm = this;
      await this.axios({
        method: "GET",
        url: "shippings/couriers/data_select_input/include_inactive/storegroup",
      })
        .then((response) => {
          vm.couriersList = response.data;
          vm.carrierList = response.data;
          vm.carrierList.push({ text: "Todos", value: 0 });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchWarehouses() {
        var vm = this;
        this.axios({
            url: "orders/warehouses/storegroup/data_select_input",
            method: "GET",
        })
        .then((response) => {
          vm.warehousesList = response.data.data;
          vm.warehousesList.unshift({ id: null, name: "Todos", order: null });
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    async fetchStores() {
        var vm = this;
        await this.axios({
            url: "orders/stores/storegroup/data_select_input",
            method: "GET",
        })
        .then((response) => {
          vm.storesList = response.data.data;
          vm.storesList.unshift({ id: null, name: "Todos", order: null });
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    fetchManifestConditionList() {
      var vm = this;

      vm.manifestConditionList.push({ id: "all", name: "Todos" });
      vm.manifestConditionList.push({ id: "yes", name: "Con manifiesto" });
      vm.manifestConditionList.push({ id: "no", name: "Sin manifiesto" });
    },
    fetchPrintedConditionList() {
      var vm = this;

      vm.printedConditionList.push({ id: "all", name: "Todos" });
      vm.printedConditionList.push({ id: "yes", name: "Impresos " });
      vm.printedConditionList.push({ id: "no", name: "No Impresos" });
    },
    fetchProducts(){
            let vm = this;
            let arreglo = [];
            this.axios({
                url: 'order/products',
                method: 'POST'
            }).then( response => {
                vm.listProducts = response.data;
                Object.keys(response.data).forEach(key => {
                    arreglo.push({
                        text: response.data[key].name + ' ('+response.data[key].sku+')',
                        value: response.data[key].sku
                    });
                });
                vm.listProducts = arreglo;
            }).catch( error => {
                console.log(error);
            });
    },
    finish() {
      console.log("finish");
      this.axios({
        url: "orders/bulk_generate_manifest",
        method: "POST",
        data: {
          shipments: this.selected.map((e) => e.id),
          carrier: this.selectCourier,
          store_id: this.selected[0].store_id,
        },
      })
        .then((response) => {
          this.fetchDataPaginated();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateMassiveTags() {
      //console.log("generateMassiveTags");
      this.axios({
        url: "shippings/generate_massive_tags",
        method: "POST",
        data: {
          shipments: this.selected.map((e) => e.id)
        },
      })
      .then((response) => {
        this.fetchDataPaginated();
        window.open(response.data.pdf, '_blank');
        
      })
      .catch((error) => {
        console.log(error);
      });
    },
    openDialogPreviewLabel(shipment) {
  
      EventBus.$emit("openDialogPreviewLabel", shipment);
    },
    openDialogModificarNroSeguimiento(shipment) {
      //this.$emit("openDialogModificarNroSeguimiento", shipment);
      EventBus.$emit("openDialogModificarNroSeguimiento", shipment);
    },
    openDialogModificarNroBultos(shipment) {
      EventBus.$emit("openDialogModificarNroBultos", shipment);
    },
    openDialogRegenerarEtiqueta(shipment) {
      EventBus.$emit("openDialogRegenerarEtiqueta", shipment);
    },
    openShippingLink(url)
    {
      window.open(url, '_blank');
    },
    openPdfLink(shipment)
    {
      console.log("PDF LINK");
      this.axios({
        url: "shippings/printed_date/"+shipment.id
      })
      .then((response) => {
        var openWindow = true;
        if (response.data.printed_alert){
          openWindow = confirm(
            `ATENCIÓN: Esta etiqueta ya fué impresa el ${this.$moment(response.data.printed).format('DD/MM/YYYY HH:mm')}. \n¿Desea continuar igualmente?`
          );
        }
        
        if (openWindow) {
          window.open(shipment.pdf, '_blank');
        }        
      })
      .catch((error) => {
        console.log(error);
      });
    },

    shipmentSelected() {
      let vm = this;
      if (vm.selected.length > 0) {
        let selectedCarrierToManifest = [];
        vm.manifestErrorSelected = false;
        selectedCarrierToManifest = vm.selected[0];
        vm.couriersList = [];
        vm.couriersList.push({ text: selectedCarrierToManifest.carrier.name, value: selectedCarrierToManifest.carrier.name });
        vm.selected.forEach((item) => {
          if (item.carrier.name != selectedCarrierToManifest.carrier.name || item.store_id != selectedCarrierToManifest.store_id) {
            vm.manifestErrorSelected = true;
            vm.couriersList = [];
          }
        });
      }
    },
    clearSelected() {
      let vm = this;
      vm.selected = [];
    },
    async fetchSources() {
        var vm = this;
        await this.axios({
            url: "admin/stores/default/marketplaces_data/storegroup",
            method: "GET",
        })
        .then((response) => {
          var mapeado = [];
          var datos = response.data;
          mapeado.push({ id: "Manual", name: "Manual" });
          datos.forEach((item) => {
            mapeado.push({
              id: item.name,
              name: item.name,
            });
          });
          vm.sourcesList = mapeado;
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    checkSelection(){
        var vm = this;
        vm.selectSources.forEach(elem => {
            if(Array.isArray(elem)){
                vm.selectSources.pop();
                var isRemove = true;
                elem.forEach(inelem => {
                    if(!vm.selectSources.includes(inelem)){
                        console.log(inelem);
                        vm.selectSources.push(inelem);
                        isRemove = false;
                    }
                });
                console.log(isRemove);
                if(isRemove){
                    elem.forEach(inelem => {
                        var index = vm.selectSources.indexOf(inelem);
                        if (index !== -1) {
                            vm.selectSources.splice(index, 1);
                        }
                    });
                }
            }
        });
        this.detectFilterChange();
    },
  },
  computed: {
    headers() {
      return [
        { text: "Tienda", value: "store" },
        { text: "# de orden", align: "left", sortable: false, value: "order_id" },
        {
          text: "Referencia",
          value:
            "order_reference" /*filter: v => (v+'').toLowerCase().includes(this['search'].toLowerCase()) */,
        },
        { text: "Número de Envío", value: "shipping_number" },
        // { text: "Email", value: "customer_email" },
        {
          text: "Fecha",
          value: "updated_at",
          //filter: (v) => this["filtroRangoFechas"](v),
        },
        { text: "Estado", value: "current_state" },
        {
          text: "Carrier",
          value: "carrier_name",
          //filter: (v) => this["filtroCarrier"](v),
        },
        { text: "Canal", value: "source.name" },
        {
          text: "Manifiesto",
          value: "manifest",
          //filter: (v) => this["filtroCarrier"](v),
        },
        { text: "Acciones", value: "acciones", sortable: false },
        { text: "Etiqueta", value: "label", sortable: false },
      ];
    },
  },
};
</script>

<style>
.dashboard_envios #icono-color-gris::before,
.dashboard_envios .icon-warehouses:before,
.dashboard_envios .icon-shipments:before{
  color: #8c8c8c !important;
}
#datepicker-starkoms .v-picker__title{
  background: linear-gradient(90deg, rgb(191, 19, 36) 0%, rgb(249, 88, 18) 100%) repeat scroll 0% 0% !important;
  
}
#datepicker-starkoms .accent
{
    background: linear-gradient(90deg, rgb(191, 19, 36) 0%, rgb(249, 88, 18) 100%) repeat scroll 0% 0% !important;
}
#datepicker-starkoms .accent--text{
    color: #e55d43 !important
}
.v-input--selection-controls{
  margin-top: 0px !important;
  margin-right: 10px;
  max-width: 100px;
  font-weight: bold;
}
.source_filter .v-input--selection-controls{
  margin-top: 0px !important;
  margin-right: 10px;
  max-width: 100px;
  font-weight: bold;
}
.source_filter .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  background-color: #f2f2f2 !important;
  padding: 0px 12px;
  border-radius: 4px !important;
}
.source_filter .v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.source_filter .theme--light.v-label {
  color: black;
}
.source_filter .v-select__slot{
  margin-top: 8px;
}
.source_filter .theme--light.v-text-field > .v-input__control > .v-input__slot:before{
  border-color: rgba(0, 0, 0, 0);
}
</style>